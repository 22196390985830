
.highlight {
    opacity: 1;
}

/* .highlight .subtitle {
    color: #ad952dee;
} */

.dimmed {
    opacity: 0.4;
}

.section-tags {
    border-radius: 16px;
    background: #ad952d66;
    color: #FFFFFFEE;
    padding: 7px 14px;
    font-size: 13px;
    width: min-content;
    display: flex;
    margin-right: 11px;
    margin-top: 11px;
    text-wrap: nowrap;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}