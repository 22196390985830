.App {
  text-decoration: inherit;
  text-decoration-line: none;
}

a {
  text-decoration: inherit;
  text-decoration-line: none;
  color: #FFFFEEEE;
  font-weight: 500;
}

a:hover {
  color: #ad952dee;

}

.nav-wrapper {
  padding-left: 0;
  margin-top: 6rem;
  max-width: 250px;
}

.nav-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1.5rem;
}

ul {
  list-style: none;
}

.active .nav-indicator {
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity));
}

.nav-indicator:hover {
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(126 232 240/var(--tw-bg-opacity));
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105/var(--tw-bg-opacity));
}

.w-8 {
  width: 2rem;
}

.h-px {
  height: 1px;
}
.mr-4 {
  margin-right: 1rem;
}

.active .nav-text {
  --tw-text-opacity: 1;
  color: #C7D1C8; /*rgb(226 232 240/var(--tw-text-opacity));*/
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color:  #9AAC9C; /*rgb(100 116 139/var(--tw-text-opacity));*/
}

.tracking-widest {
  letter-spacing: .1em;
}

.uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 700;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.icons-link-wrapper {
  margin-top: 20px;
}